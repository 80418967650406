import { Inject, Injectable } from '@angular/core';
import { AccountType } from '@app/constants/account-type.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { IAccountCreateDto } from '@app/interfaces/account-create-dto.interface';
import { IAccount } from '@app/interfaces/account.interface';
import { DeviceInfoService } from '@app/services/device-info/device-info.service';
import { CategoryName } from '@app/states/events/constants/category-name.const';
import { EventName } from '@app/states/events/constants/event-name.const';
import { MetricType } from '@app/states/events/constants/metric-type.const';
import { EventsActions } from '@app/states/events/states/events.actions';
import { ModalEvent } from '@app/states/modal/constants/modal-event.const';
import { ModalActions } from '@app/states/modal/states/modal.actions';
import { IInteractivePaymentResponse } from '@app/states/product/interfaces/interactive-payment-response.interface';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { RequestStatus } from 'projects/web-bankir-app/src/app/constants/request-status.const';
import { interval, of, throwError, timer } from 'rxjs';
import { catchError, finalize, map, repeatWhen, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { getPayment3dsFormConfig } from '../helpers/payment-3ds-handler-config.helper';
import { AccountsService } from '../services/accounts.service';
import { ACCOUNTS_STATE_DEFAULTS } from './accounts-state-defaults.const';
import { AccountsActions } from './accounts.actions';
import { IAccountsState } from './accounts.state.interface';
import { EventMethodType } from '@app/states/events/constants/event-method-type.const';
import { getApiRequestEventData } from '@app/states/events/helpers/get-default-api-request-event.helper';
import { EVENT_NAME_MAP } from '@app/states/events/constants/event-name-map.const';

@State<IAccountsState>({
  name: 'Accounts',
  defaults: ACCOUNTS_STATE_DEFAULTS,
})
/**
 * Класс NGXS состояния счетов пользователя
 */
@Injectable()
export class AccountsState extends StateErrorHandler {
  /**
   * Конструктор класса состояния счетов пользователя
   *
   * @param store NGXS{@link Store} хранилище
   * @param service [Сервис]{@link AccountsService} счетов пользователя
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   * @param window [Window]{@link Window} BrowserAPI
   */
  constructor(
    protected store: Store,
    private service: AccountsService,
    protected toast: ToastService,
    @Inject('Window') private window: Window,
    private deviceInfoService: DeviceInfoService
  ) {
    super(toast, store);
  }

  /**
   * Селектор, возвращающий счет виртуальной карты пользователя
   * @param state состояние счетов пользователя
   */
  @Selector()
  public static VirtualCardAccount(state: IAccountsState): IAccount {
    return state.data?.find((a) => a.type === AccountType.VirtualCard);
  }

  /**
   * Селектор, возвращающий Qiwi счет пользователя
   * @param state состояние счетов пользователя
   */
  @Selector()
  public static QiwiAccount(state: IAccountsState): IAccount {
    return state.data?.find((a) => a.type === AccountType.QIWI);
  }

  /**
   * Селектор, возвращающий основной счет пользователя
   * @param state состояние счетов пользователя
   */
  @Selector()
  public static main(state: IAccountsState): IAccount {
    return state.data?.find((a) => !!a.main);
  }

  /**
   * Селектор, возвращающий основной счет пользователя или единственный указанный счет
   * @param state состояние счетов пользователя
   */
  @Selector()
  public static actualPaymentMethod(state: IAccountsState): IAccount {
    if (!state.data) {
      return null;
    }
    return state.data.length === 1 ? state.data[0] : state.data?.find((item) => !!item?.main);
  }

  /**
   * Действие для загрузки счетов пользователя
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Запросы к бэкенду {@link AccountsService#accountsRequest}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#LoadSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#LoadFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.Load, { cancelUncompleted: true })
  public load(ctx: StateContext<IAccountsState>, action: AccountsActions.Load) {
    ctx.setState(
      patch({
        status: RequestStatus.Pending,
      })
    );
    return this.service.accountsRequest().pipe(
      repeatWhen(() => (action.needRepeatRequest ? interval(2000).pipe(take(5)) : of(true))),
      takeWhile((response) => !response.data.length, true),
      map((response) => {
        // eslint-disable-next-line max-len
        if (response.data.length <= 0) {
          this.catchSentryError('AccountsActions.LoadFail', 'Пустой массив данных счета/карты в ответе GET /accounts');
        }
        ctx.dispatch(
          new AccountsActions.LoadSuccess(
            response.data.filter(
              (account: IAccount) =>
                // WEBDEV-1767: убрать "Контакт"
                account.type !== AccountType.Contact
            )
          )
        );
      }),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.LoadFail', err);
        return ctx.dispatch(new AccountsActions.LoadFail(err.error?.errors));
      }),
      finalize(() => {
        if (action.needRepeatRequest && !ctx.getState().data.length) {
          this.toast.notification({
            title: 'Ошибка',
            text: 'Проблема с привязкой счета. Попробуйте позже',
            type: ToastType.Error,
            code: '000001',
          });
        }
      })
    );
  }

  /**
   * Действие для успешной загрузки счетов пользователя
   *
   * Порядок выполнения:
   * - Обновить состояние данных о счетах, изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.LoadSuccess) {
    ctx.setState(
      patch({
        data: action.payload.sort((a, b) => b.main - a.main),
        status: RequestStatus.Load,
      })
    );
  }

  /**
   * Действие для неуспешной загрузки счетов пользователя
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.LoadFail)
  public loadFail(ctx: StateContext<IAccountsState>, action: AccountsActions.LoadFail) {
    ctx.setState(
      patch({
        status: RequestStatus.Error,
      })
    );

    this.catchError(action.payload);
  }

  /**
   * Действие для загрузки списка банков
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#banks}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#BanksSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#BanksFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.Banks)
  public banks(ctx: StateContext<IAccountsState>, action: AccountsActions.Banks) {
    return this.service.banks().pipe(
      tap((response) =>
        ctx.dispatch(
          new AccountsActions.BanksSuccess(
            response.data.map((item) => ({ value: item.id, label: item.attributes.name }))
          )
        )
      ),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.BanksFail', err);
        return ctx.dispatch(new AccountsActions.BanksFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешной загрузки списка банков
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.BanksFail)
  public banksFail(ctx: StateContext<IAccountsState>, action: AccountsActions.BanksFail) {}

  /**
   * Действие для успешной загрузки списка банков
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.BanksSuccess)
  public banksSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.BanksSuccess) {}

  /**
   * Действие для загрузки данных банка
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#bankCard}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#CardBankSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#CardBankFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CardBank)
  public cardBank(ctx: StateContext<IAccountsState>, action: AccountsActions.CardBank) {
    return this.service.bankCard(action.payload).pipe(
      tap((response) => ctx.dispatch(new AccountsActions.CardBankSuccess(response?.result?.data))),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.CardBankFail', err);
        return ctx.dispatch(new AccountsActions.CardBankFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для проверки карты пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#checkCard}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#CheckCardSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#CheckCardFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CheckCard)
  public checkCard(ctx: StateContext<IAccountsState>, action: AccountsActions.CheckCard) {
    const { card, expire, method } = ctx.getState().form.model;

    return this.service
      .checkCard({
        valid_month: parseInt(expire.slice(0, 2), 10),
        valid_year: parseInt(expire.slice(2, 4), 10),
        main: 1,
        name: '',
        type: method?.value,
        description: card,
      })
      .pipe(
        tap((response) => ctx.dispatch(new AccountsActions.CheckCardSuccess(response.data))),
        catchError((err, caught) => {
          this.catchSentryError('AccountsActions.CheckCardFail', err);
          return ctx.dispatch(new AccountsActions.CheckCardFail(err.error?.errors));
        })
      );
  }

  /**
   * Действие для неуспешной проверки карты пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CheckCardFail)
  public checkCardFail(ctx: StateContext<IAccountsState>, action: AccountsActions.CheckCardFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной проверки карты пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CheckCardSuccess)
  public checkCardSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.CheckCardSuccess) {}

  /**
   * Действие для добавления карты пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#bindCard}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#BindCardSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#BindCardFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.BindCard)
  public bindCard(ctx: StateContext<IAccountsState>, action: AccountsActions.BindCard) {
    const userId = this.store.selectSnapshot((state) => state.Auth?.userId);
    const formModel = ctx.getState().form.model;
    const { card, expire, cvv } = formModel;
    const attributes = {
      clientId: `${userId}`,
      card: {
        cvc: cvv,
        cvv,
        date: expire.slice(0, 2) + '/' + expire.slice(2, 4),
        holder: 'UNKNOWN NAME',
        month: expire.slice(0, 2),
        year: expire.slice(2, 4),
        number: card,
      },
      otp: action.payload,
      clientInfo: {
        challengeWindowSize: '05',
        language: this.window.navigator.language,
        screenHeight: this.window.screen.height,
        screenWidth: this.window.screen.width,
        timezone: String(new Date().getTimezoneOffset()),
        javaEnabled: this.window.navigator?.javaEnabled() ?? false,
        javascriptEnabled: true,
        colorDepth: [1, 4, 8, 15, 16, 24, 32, 48].includes(this.window.screen.colorDepth)
          ? this.window.screen.colorDepth
          : 48,
      },
    };

    return this.service.bindCard({ type: 'Payment', attributes }).pipe(
      switchMap((response) => {
        if (response.body?.data.type === 'TdsMethod') {
          this.submitTdsForm(response.body.data);
          return timer(response.body.data.attributes.timeout * 1000).pipe(
            switchMap(() => {
              return this.service.bindCard({
                type: 'Payment',
                attributes: {
                  ...attributes,
                  paymentId: response.body?.data?.attributes?.paymentId,
                },
              });
            })
          );
        } else {
          return of(response);
        }
      }),
      tap((response) => {
        ctx.dispatch(new AccountsActions.BindCardSuccess(response.body?.data));
        ctx.dispatch(new EventsActions.RpcEvent({
          data: getApiRequestEventData({status_code: response.status, action: MetricType.CardBinding}),
          method: EventMethodType.Add,
        },true))
      }),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.BindCardFail', err);
        ctx.dispatch(new AccountsActions.BindCardFail(err.error?.errors, err.error?.isHandled));
        ctx.dispatch(new EventsActions.RpcEvent({
          data: getApiRequestEventData({status_code: err.status, action: MetricType.CardBinding}),
          method: EventMethodType.Add
        },true))
        return throwError(() => err);
      }),
      finalize(() => {
        if(!!this.store.selectSnapshot(state => state.Product?.prolongation?.refinancing)) {
          this.store.dispatch(new EventsActions.RpcEvent({
            data: {
              '@type': EVENT_NAME_MAP.get(EventName.SmsCodeEntered), 
              name: EventName.SmsCodeEntered,
              category: CategoryName.Refinancing,
              payload: {
                event_type: 'user_action',
              },
            },
            method: EventMethodType.Create,
          }));
        }
      })
    );
  }

  /**
   * Действие для неуспешного добавления карты пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.BindCardFail)
  public bindCardFail(ctx: StateContext<IAccountsState>, action: AccountsActions.BindCardFail) {
    this.catchError(action.payload, !action.isHandled);
  }

  /**
   * Действие для успешного добавления карты пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.BindCardSuccess)
  public bindCardSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.BindCardSuccess) {}

  @Action(AccountsActions.SendCode)
  public sendCode(ctx: StateContext<IAccountsState>, action: AccountsActions.SendCode) {
    const { hash } = this.store.selectSnapshot((s) => s.RiskAnalysis || {});
    return this.service
      .sendCode({
        paymentMethod: action.payload,
        browserTracking: hash,
        deviceFingerprint: this.deviceInfoService.info?.deviceFingerprint,
      })
      .pipe(
        tap((res) => ctx.dispatch(new AccountsActions.SendCodeSuccess(res))),
        catchError((err, caught) =>
          ctx.dispatch(new AccountsActions.SendCodeFail(err.error.errors || [], err.status, err.error?.meta?.time_left))
        )
      );
  }

  @Action(AccountsActions.SendCodeFail)
  public sendCodeFail(ctx: StateContext<IAccountsState>, action: AccountsActions.SendCodeFail) {
    ctx.dispatch(new AccountsActions.WatchSmsCode(action));
    this.catchError(action.payload);
  }

  @Action(AccountsActions.SendCodeSuccess)
  public sendCodeSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.SendCodeSuccess) {
    ctx.dispatch(new AccountsActions.WatchSmsCode(action));
  }

  /**
   * Действие для добавления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#createAccount}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#CreateSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#CreateFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.Create)
  public create(ctx: StateContext<IAccountsState>, action: AccountsActions.Create) {
    const { card, expire, method, bank } = ctx.getState().form.model;

    const data: Partial<IAccountCreateDto> = {
      main: 1,
      type: method?.value,
    };

    switch (method?.value) {
      case AccountType.Card:
        data.valid_month = parseInt(expire.slice(0, 2), 10);
        data.valid_year = parseInt(expire.slice(0, 2), 10);
        data.description = card;
        break;
      case AccountType.SBP:
        const sbp = ctx.getState().data?.find((acc) => acc.type === AccountType.SBP);

        if (sbp) {
          return ctx
            .dispatch(new AccountsActions.Delete(sbp.id, false, false))
            .pipe(tap(() => ctx.dispatch(new AccountsActions.Create())));
        }

        const phone = this.store.selectSnapshot((state) => state.profile?.form?.model?.phone);

        data.name = bank.label;
        data.description = '+7' + phone;
        data.bic = bank.value;
        break;
      case AccountType.YooMoney:
        data.description = card;
        if (action.payload) {
          data.otp = action.payload;
        }
        break;
      case AccountType.VirtualCard:
        data.description = card;
        break;
    }

    return this.service.createAccount(data).pipe(
      tap((response) => ctx.dispatch(new AccountsActions.CreateSuccess(response.data[0]))),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.CreateFail', err);
        return ctx.dispatch(new AccountsActions.CreateFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного добавления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CreateFail)
  public createFail(ctx: StateContext<IAccountsState>, action: AccountsActions.CreateFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного добавления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешном добавлении способа оплаты
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.CreateSuccess)
  public createSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.CreateSuccess) {
    this.toast.notification({
      title: 'Успех',
      text: `Счет успешно привязан`,
      type: ToastType.Success,
    });
  }

  /**
   * Действие для удаления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#deleteRequest}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#DeleteSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#DeleteFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.Delete)
  public delete(ctx: StateContext<IAccountsState>, action: AccountsActions.Delete) {
    return this.service.deleteRequest(action.payload).pipe(
      map(() => ctx.dispatch(new AccountsActions.DeleteSuccess(action.payload, action.notify, action.isResetForm))),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.DeleteFail', err);
        return ctx.dispatch(new AccountsActions.DeleteFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного удаления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Удаление из состояния удаленного способа оплаты
   * - Обращение к [действию]{@link AccountsActions#Load} для обновления данных о способах оплаты пользователя
   * - В случае переданного notify true, вывод информационного сообщения об успешном удалении способа оплаты
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.DeleteSuccess)
  public deleteSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.DeleteSuccess) {
    ctx.setState(
      patch({
        data: removeItem((item: IAccount) => item.id === action.payload),
      })
    );
    if (action.isResetForm) {
      ctx.dispatch(new AccountsActions.ResetForm());
    }
    ctx.dispatch(new AccountsActions.Load());

    if (action.notify) {
      this.toast.notification({
        title: 'Успех',
        text: 'Счет успешно удален',
        type: ToastType.Success,
      });
    }
  }

  /**
   * Действие для неуспешного удаления способа оплаты пользователя
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link AccountsActions#Load} для обновления данных о способах оплаты пользователя
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.DeleteFail)
  public deleteFail(ctx: StateContext<IAccountsState>, action: AccountsActions.DeleteFail) {
    ctx.dispatch(new AccountsActions.Load());

    this.catchError(action.payload);
  }

  /**
   * Действие для установления способа оплаты пользователя основным
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link AccountsService#makeDefault}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AccountsActions#MakeDefaultSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AccountsActions#MakeDefaultFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.MakeDefault)
  public makeDefault(ctx: StateContext<IAccountsState>, action: AccountsActions.MakeDefault) {
    return this.service.makeDefault(action.payload).pipe(
      map(() => ctx.dispatch(new AccountsActions.MakeDefaultSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.MakeDefaultFail', err);
        return ctx.dispatch(new AccountsActions.MakeDefaultFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного установления способа оплаты пользователя основным
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link AccountsActions#Load} для обновления данных о способах оплаты пользователя
   * - Вывод информационного сообщения об успешном установлении способа оплаты пользователя основным
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.MakeDefaultSuccess)
  public makeDefaultSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.MakeDefaultSuccess) {
    ctx.dispatch(new AccountsActions.Load());

    this.toast.notification({
      title: 'Успех',
      text: 'Счет успешно сделан основным',
      type: ToastType.Success,
    });
  }

  @Action(AccountsActions.BindOptions)
  public bindOptions(ctx: StateContext<IAccountsState>, action: AccountsActions.BindOptions) {
    this.service.bindOptions().pipe(
      tap(() => ctx.dispatch(new AccountsActions.BindOptionsSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('AccountsActions.BindOptionsFail', err);
        return ctx.dispatch(new AccountsActions.BindOptionsFail(err.error?.errors));
      })
    );
  }

  @Action(AccountsActions.BindOptionsSuccess)
  public bindOptionsSuccess(ctx: StateContext<IAccountsState>, action: AccountsActions.BindOptionsSuccess) {}

  @Action(AccountsActions.BindOptionsFail)
  public bindOptionsFail(ctx: StateContext<IAccountsState>, action: AccountsActions.BindOptionsFail) {
    this.catchError(action.payload);
  }

  @Action(AccountsActions.Payment3dsHandler)
  public payment3dsHandler(ctx: StateContext<IAccountsState>, action: AccountsActions.Payment3dsHandler) {
    ctx.dispatch(
      new ModalActions.OpenModal({
        route: ['payment-3ds'],
        data: getPayment3dsFormConfig(action.payload),
        events: {
          [ModalEvent.onClose]: action.callback,
        },
      })
    );
  }

  /**
   * Действие для неуспешного установления способа оплаты пользователя основным
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link AccountsActions#Load} для обновления данных о способах оплаты пользователя
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.MakeDefaultFail)
  public makeDefaultFail(ctx: StateContext<IAccountsState>, action: AccountsActions.MakeDefaultFail) {
    ctx.dispatch(new AccountsActions.Load());

    this.catchError(action.payload);
  }

  /**
   * Действие для сброса состояния формы к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(AccountsActions.ResetForm)
  public resetForm(ctx: StateContext<IAccountsState>, action: AccountsActions.ResetForm) {
    ctx.patchState({
      form: ACCOUNTS_STATE_DEFAULTS.form,
    });
  }

  @Action(AccountsActions.ResetConfirmation)
  public ResetConfirmation(ctx: StateContext<IAccountsState>, action: AccountsActions.ResetConfirmation) {
    ctx.patchState({
      confirmation: ACCOUNTS_STATE_DEFAULTS.confirmation,
    });
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   */
  @Action(AccountsActions.Reset)
  public reset(ctx: StateContext<IAccountsState>) {
    ctx.setState(ACCOUNTS_STATE_DEFAULTS);
  }

  /**
   * Действие для отправки смс
   *
   * @param ctx
   */
  @Action(AccountsActions.WatchSmsCode)
  public watchSmsCode(ctx: StateContext<IAccountsState>, action: AccountsActions.WatchSmsCode) {
    const confirmation = ctx.getState().confirmation?.model;

    let sendCount = (confirmation?.sendCount ?? 0) + 1;
    let nextAttemptIn = 0;
    if (action instanceof AccountsActions.SendCodeFail) {
      nextAttemptIn = Number(action?.payload?.[0].code.replace(/^\D+/g, ''));
    } else if (action.payload instanceof AccountsActions.SendCodeSuccess) {
      nextAttemptIn = action.payload?.payload?.resendDelay;
    }
    if (nextAttemptIn >= 86400) {
      sendCount = 5;
    }
    ctx.dispatch(
      new UpdateFormValue({
        path: 'Accounts.confirmation',
        value: {
          ...confirmation,
          refresh: new Date(+new Date() + nextAttemptIn * 1000).toISOString(),
          inputCount: 0,
          sendCount,
        },
      })
    );
  }

  private submitTdsForm(response: IInteractivePaymentResponse): void {
    const iframe = this.window.document.createElement('iframe');
    const form = this.window.document.createElement('form');
    const data = getPayment3dsFormConfig(response);

    iframe.setAttribute('id', 'pay_frame');
    iframe.setAttribute('name', 'Pay_Tds');

    form.setAttribute('action', data.url);
    form.setAttribute('method', data.method);
    form.setAttribute('target', 'Pay_Tds');

    data.params.map((param) => {
      const input = this.window.document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', param.name);
      input.setAttribute('value', param.value);

      form.appendChild(input);
    });

    iframe.appendChild(form);
    this.window.document.body.appendChild(iframe);
    form.submit();
    this.window.document.body.removeChild(iframe);
  }
}
